@import url('https://fonts.googleapis.com/css2?family=Ramaraja&display=swap');
:root {
  --cardWidth: 240px;
  --cardHeight: 280px;
  --cardMargin: 16px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.nav-item a{
font-weight: bold;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: #faf9f9;
}
.card {
  width: var(--cardWidth);
  height: var(--cardHeight);
  margin: var(--cardMargin);
  border: 1px solid rgb(247, 247, 247);
  border-radius: 8px;
  background: #fff;
  box-sizing: border-box;
}
.content {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 15px rgba(0,0,0,0.1);

  transition: transform 1s;
  transform-style: preserve-3d;
}

.card:hover .content {
  transform: rotateY( 180deg ) ;
  transition: transform 0.5s;
}

.front,
.back {
  position: absolute;
  height: 100%;
  width: 100%;
  background: white;
  padding-top: 10px;
  color: #03446A;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.back {
  background: #ffffff;
  color: #03446A;
  transform: rotateY( 180deg );
}


.text-blk{
  margin-top:0px;
  margin-right:0px;
  margin-bottom:0px;
  margin-left:0px;
  padding-top:10px;
  padding-right:10px;
  padding-bottom:10px;
  padding-left:10px;
  line-height:25px;
}
.responsive-cell-block{
  min-height:75px;
}
.responsive-container-block{
  min-height:75px;
  height:fit-content;
  width:100%;
  padding-top:10px;
  padding-right:10px;
  padding-bottom:10px;
  padding-left:10px;
  display:flex;
  flex-wrap:wrap;
  margin-top:0px;
  margin-right:auto;
  margin-bottom:0px;
  margin-left:auto;
  justify-content:flex-start;
}
.responsive-container-block.bigContainer{
  padding-top:10px;
  padding-right:30px;
  padding-bottom:10px;
  padding-left:30px;
}
.responsive-container-block.Container{
  max-width:1320px;
  padding-top:10px;
  padding-right:10px;
  padding-bottom:10px;
  padding-left:10px;
  margin-top:50px;
  margin-right:auto;
  margin-bottom:50px;
  margin-left:auto;
}
.text-blk.headingText{
  font-size:48px;
  line-height:50px;
  font-weight:900;
  display:flex;
  flex-direction:column;
  margin-top:0px;
  margin-right:0px;
  margin-bottom:15px;
  margin-left:0px;
  padding-top:0px;
  padding-right:0px;
  padding-bottom:0px;
  padding-left:0px;
}
.text-blk.subHeadingText{
  line-height:30px;
  color:rgb(122, 122, 122);
  margin-top:0px;
  margin-right:0px;
  margin-bottom:0px;
  margin-left:0px;
  padding-top:0px;
  padding-right:0px;
  padding-bottom:0px;
  padding-left:0px;
}
.responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-mobile-12.wk-tab-6{
  background-color:rgb(209, 51, 51);
}
.text-blk.name{
  font-size:18px;
  font-weight:800;
  line-height:24px;
  padding-top:15px;
  padding-right:10px;
  padding-bottom:5px;
  padding-left:0px;
  margin-top:0px;
  margin-right:0px;
  margin-bottom:0px;
  margin-left:0px;
}
.text-blk.position{
  line-height:24px;
  padding-top:10px;
  padding-right:10px;
  padding-bottom:10px;
  padding-left:0px;
  margin-top:0px;
  margin-right:0px;
  margin-bottom:0px;
  margin-left:0px;
}
.carrd{
  display:block;
  flex-direction:column;
  align-items:center;
  padding-top:10px;
  padding-right:10px;
  padding-bottom:10px;
  padding-left:10px;
}
.cardImg{
  width:100%;
  height:250px;
  margin-top:0px;
  margin-right:auto;
  margin-bottom:0px;
  margin-left:auto;
  max-width:300px;
}
.responsive-cell-block.wk-desk-6.wk-ipadp-6.wk-mobile-12.wk-tab-12{
  display:inline-block;
  margin-top:0px;
  margin-right:0px;
  margin-bottom:40px;
  margin-left:0px;
}
.responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-4.wk-ipadp-4{
  padding-top:0px;
  padding-right:10px;
  padding-bottom:0px;
  padding-left:0px;
}
.responsive-cell-block.wk-desk-8.wk-ipadp-8.wk-tab-12.wk-mobile-12.team-members-container{
  display:flex;
  flex-wrap:wrap;
}
@media (max-width: 1024px){
  .text-blk.name{
    margin-top:0px;
    margin-right:0px;
    margin-bottom:0px;
    margin-left:0px;
    padding-top:10px;
    padding-right:10px;
    padding-bottom:5px;
    padding-left:0px;
  }
  .text-blk.position{
    margin-top:0px;
    margin-right:0px;
    margin-bottom:0px;
    margin-left:0px;
    padding-top:0px;
    padding-right:10px;
    padding-bottom:10px;
    padding-left:0px;
  }
  .cardImg{
    width:100%;
    min-width:100%;
    height:250px;
    max-height:300px;
    min-height:250px;
  }
  .cardImg{
    min-height:230px;
    max-height:230px;
  }
}
@media (max-width: 768px){
  .text-blk.position{
    margin-top:0px;
    margin-right:0px;
    margin-bottom:0px;
    margin-left:0px;
  }
  .text-blk.name{
    margin-top:0px;
    margin-right:0px;
    margin-bottom:0px;
    margin-left:0px;
  }
  .text-blk.headingText{
    text-align:center;
    padding-top:10px;
    padding-right:0px;
    padding-bottom:10px;
    padding-left:0px;
  }
  .text-blk.subHeadingText{
    text-align:center;
    color:rgb(122, 122, 122);
    max-width:500px;
    margin-top:0px;
    margin-right:auto;
    margin-bottom:0px;
    margin-left:auto;
  }
  .responsive-cell-block.wk-desk-8.wk-ipadp-8.wk-tab-12.wk-mobile-12{
    padding-top:0px;
    padding-right:0px;
    padding-bottom:0px;
    padding-left:0px;
    margin-top:30px;
    margin-right:0px;
    margin-bottom:0px;
    margin-left:0px;
  }
  .carrd{
    display:flex;
  }
  .cardImg{
    max-width:300px;
    min-width:250px;
  }
  .responsive-cell-block.wk-tab-12.wk-mobile-12.wk-desk-4.wk-ipadp-4{
    padding-top:0px;
    padding-right:0px;
    padding-bottom:0px;
    padding-left:0px;
  }
  .carrd{
    padding-top:0px;
    padding-right:0px;
    padding-bottom:0px;
    padding-left:0px;
  }
  .text-blk.headingText{
    padding-top:0px;
    padding-right:0px;
    padding-bottom:0px;
    padding-left:0px;
  }
  .responsive-container-block.Container{
    padding-top:0px;
    padding-right:0px;
    padding-bottom:0px;
    padding-left:0px;
  }
}
@media (max-width: 500px){
  .text-blk.headingText{
    text-align:left;
    line-height:65px;
  }
  .text-blk.subHeadingText{
    color:rgb(122, 122, 122);
    text-align:left;
  }
  .text-blk.name{
    margin-top:0px;
    margin-right:0px;
    margin-bottom:0px;
    margin-left:0px;
    padding-top:10px;
    padding-right:0px;
    padding-bottom:5px;
    padding-left:0px;
  }
  .text-blk.position{
    margin-top:0px;
    margin-right:0px;
    margin-bottom:0px;
    margin-left:0px;
    padding-top:0px;
    padding-right:0px;
    padding-bottom:10px;
    padding-left:0px;
  }
  .responsive-container-block.Container{
    margin-top:80px;
    margin-right:0px;
    margin-bottom:50px;
    margin-left:0px;
    padding-top:0px;
    padding-right:0px;
    padding-bottom:0px;
    padding-left:0px;
  }
  .carrd{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
  }
  .responsive-container-block.bigContainer{
    padding-top:10px;
    padding-right:20px;
    padding-bottom:10px;
    padding-left:20px;
  }
  .cardImg{
    width:100%;
  }
  .text-blk.headingText{
    font-size:40px;
    line-height:45px;
  }
  .cardImg{
    min-width:auto;
  }
}
@media (min-width: 1025px) {
  .h-custom-2 {
    height: 100%;
    }
  }

  .heading{
    width: 400px;
    text-align: center;
  }
  
  .boxx{
    width: 300px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   color: #343a40;
   font-weight: bold;
    
    border-radius: 12px;
   
   
  }
  
  .custom-form{
    width: 100%;
  }
  
  .btn{
    width: 100%;
    margin-top: 5px;
  }
  
  .error-msg{
    width: 100%;
    color: red;
    font-size: 14px;
    font-weight: 600;
  }
  
  .info-msg{
    width: 100%;
    color: green;
    font-size: 14px;
    font-weight: 600;
  }
  
  .photo{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #e4e4e4;
  }
  
  .photo img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  
  h3{
    margin-top: 5px;
  }
 
  .wrapcontainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    min-height: 100vh;
    
  }
  .view {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    display: none;
  }
  
  .active {
    opacity: 1;
    display: block;
    margin: auto;
  }
  
  
  
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff6347;
  }
  
  .modal-content {
    position: relative;
   
    transform: translateY(50px);
    animation: fadeIn 0.3s forwards;
  }
  
  .modal-header {
    display: flex;
    
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
  }
  
  .modal-title {
    font-size: 10px;
    font-weight: bold;
    color: white;
  }
  
  .close-modal {
    cursor: pointer;
  }
  
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .form-label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-control {
    padding: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
  }
  
  .payment-type-dropdown {
    width: 300px;
    margin-bottom: 20px;
  }
  
  .qr-code-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    flex-direction: column;
  }
  
  .qr-code {
    max-width: 100%;
  }
  
  .deposit-button {
    background-color: #1976d2;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .deposit-button:hover {
    background-color: #135ba1;
  }
  
  @media (max-width: 500px) {
    .modal-content {
      width: 90%;
    }
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      align-items: center;
    }
  
    .center-pages {
      margin-top: 20px;
      text-align: center;
      flex-direction: column;
    }
  
    .login-buttons {
      margin-top: 20px;
      text-align: center;
    }
  }
  /* HeroSection.css */
.hero-section {
  display: flex;
  align-items: center;
  
  height: 100vh;
  overflow: hidden;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height:75vh;
  overflow: hidden;
  z-index: -1;
}

.background-video {
  object-fit: cover;
  width: 100%;
  height:75vh;
  
}
.video-container::before {
  content: '';
  background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75vh;
}
.cont {
  position: absolute;
  padding: 20px;
  border-radius: 10px;
  margin-left: 15%; 
 /* Adjust the margin as needed */
  text-align: left;
  width: 450px;
 top:13%
}

.header {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 10px;
}

.text {
  color: white;
  margin-bottom: 20px;
}

.action-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.action-button:hover {
  background-color: #0056b3;
}

/* Media Query for Responsive Design */
@media (max-width: 768px) {
  .hero-section {
    flex-direction: column-reverse;
  }

  .cont {
    margin-left: 0;
    margin-top: 20px; /* Add margin-top to separate the content from the video on mobile */
    text-align: center;
  }
 .crypto-container {
    flex-wrap:wrap
   }
}
/* Toast.css */
.toast-container {
 
 
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index:90000 ;
  background-color: #071f4b;
}

.toast {
  background-color: #333;
  color: rgb(226, 40, 40);
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 10px;
  max-width: 300px;
  word-wrap: break-word;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
}
.section-container {
  background-image: url('world_map.svg'); /* Set your background image */
  background-size: cover;
  background-position: center;
  padding: 40px;
  color: white;
  margin: auto;
  margin-top: -30vh;
  max-width: 70%;
  border-radius: 20px;
}

.section-header {
  text-align: left;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}

.box {
  flex-basis: calc(33.33% - 20px);
  background-color: rgba(0, 0, 0, 0);
  padding: 20px;
  border-radius: 10px;
  text-align: left;
  margin-bottom: 20px;
}

.box h2 {
  font-size: 40px;
  margin-bottom: 10px;
  color: white;
}

.box-number {
  font-size: 36px;
  font-weight: bold;
  color: #007bff;
}

.box p {
  color: white;
}
.centered-section {
  width: 70%;
  margin: 5% auto;
  display: flex;
  justify-content: space-between;
  height: 600px;
}

.left-component {
  width: 30%;
  padding: 20px;
  border: 1px solid #ccc;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-image: url(./world_map.svg);
  background-position: center;
  background-size: cover;
  color: #e4e4e4;
}

.left-component h1 {
  font-size: 40px;
  text-align: left;
  color: #ccc;
}

.right-component {
  width: 70%;
}

.image-container {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: background-image 0.5s ease-in-out;
  position: relative;
}

.button-container {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.button-container button {
  margin-right: 10px;
}
/* CryptoPrices.css */
.crypto-container {
  display: flex;
  
  width: 80%;
  gap: 5px;
  padding: 15px;
  margin: 10% auto;
  background-color: #ddd7d7;
  border-radius: 10px;
 
}

.crypto-card {
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 

  @media (min-width: 768px) {
    width: calc(33.33% - 20px);
  }
}

.crypto-card h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.crypto-card p {
  font-size: 18px;
  margin-bottom: 10px;
}

.crypto-card button {
  background-color: hsl(135, 96%, 41%);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.crypto-card button:hover {
  background-color: #0056b3;
}
/* FortySixtyLayout.css */
.custom-container {
  display: flex;
  flex-direction: column;
  margin: 10% auto;
  width: 80%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.custom-left-section {
  width: 100%;
  flex: 1;
  background-color: #f2f2f2;
  padding: 20px;
  text-align: left;


  @media (min-width: 768px) {
    width: 40%;
    flex: initial;
  }
}

.custom-left-section h1 {
  font-size: 40px;
  margin-bottom: 20px;
}

.custom-left-section button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.custom-left-section button:hover {
  background-color: #0056b3;
}

.custom-right-section {
  width: 100%;
  flex: 1;
  padding: 20px;
text-align: left;

  @media (min-width: 768px) {
    width: 60%;
    flex: initial;
  }
}

.custom-right-section p {
  font-size: 16px;
  line-height: 1.5;
  width: 80%;
}

.nav {
  position: fixed;
  top: 0;
  left: -250px; /* Initially hidden off-screen */
  width: 250px;
  height: 100%;
  background-color: #333;
  transition: left 0.3s ease;
}

/* Opened navigation */
.nav.open {
  left: 0;
}

/* Hamburger icon styles */
.hamburger {
  cursor: pointer;
  padding: 15px;
  position: absolute;
  top: 15px;
  left: 15px;
  display: none;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px 0;
  transition: 0.4s;
}

/* Navigation links styles */
.nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nav-links li {
  margin: 10px 0;
}

.nav-links a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #00bcd4;
}
/* media queries*/

@media (max-width: 768px) {
 
  /* You can add more styles specific to small screens here */
}

/* Media query for screens with a maximum width of 800px (typical for mobile phones) */
@media (max-width: 800px) {
 .cont{
   width:300px
 } 
 .section-header{
   text-align: center;
 }
 h1{
  font-size: 1.5rem;
 }
 .flex-container{
   justify-content: center;
 }
 .box{
   flex-basis: auto;
 }
 .custom-left-section,.custom-right-section{
   text-align: center;
 }
 .custom-right-section p{
   text-align: center;
   margin: auto;
 }
.crypto-container{
  justify-content: center;
  /* Additional styles for even smaller screens go here */
}
.centered-section{
  width: 100%;
  height: auto;
  flex-direction: column;

}
.left-component{
  width: 100%;
}
.right-component{
  display: none;
}
.center-pages{
  display: none !important;
}
.hamburger{
  display: block;
  z-index: 1001;
}
}
.wrapper {
  width: 95%;
  max-width: 1200px;
  margin: 30px auto;
}
.pricing-table h2 {
  font-size: 1.5em;
  padding: 35px 10px;
  margin: 0;
}
.description {
  margin-top: 20px;
  font-size: 0.85em;
  padding: 0 40px;
  font-style: italic;
}

.description {
  margin-top: 20px;
  font-size: 0.85em;
  padding: 0 40px;
  font-style: italic;
}
.price,
.pricing-box {
  -webkit-transition: all ease-out 0.2s;
  transition: all ease-out 0.2s;
}
.price {
  background: #4a8eb2;
  color: #fff;
  font-size: 2em;
  font-weight: 600;
  position: relative;
  padding: 20px 10px;
  display: block;
}
.pricing-box:nth-child(2) .price {
  background: #216f99;
}
.pricing-box:nth-child(3) .price {
  background: #02537f;
}
.pricing-box:hover .price {
  background: #ff0;
  box-shadow: inset 0 0 100px 0 rgba(0, 0, 0, 0.3);
  color: #000;
}
.btn {
  background: #ff0;
  border: 1px solid #d9d909;
  color: #000;
  -webkit-transition: all ease-out 0.2s;
  transition: all ease-out 0.2s;
  border-radius: 30px;
  padding: 7px 40px;
  display: inline-block;
  margin-top: 20px;
}
.btn:hover {
  background: #093e64;
  border: 1px solid #093e64;
  color: #fff !important;
}
.pricing-table-divider {
  display: block;
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
  max-width: 80%;
  margin: 20px auto 0;
}
.pricing-table {
  border-radius: 3px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[4];
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  text-align: center;
}

.pricing-box {
 
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.13);
  border: 2px solid rgba(10, 123, 183, 0.14);
}
.pricing-box:hover {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
}
.pricing-table ul li {
  padding: 3px 0;
  font-size: 0.95em;
}
.pricing-table ul {
  color: #000;
  padding: 15px 60px;
  text-align: left;
}
@media (max-width: 750px) {
  .pricing-table {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .pricing-table > div:nth-child(3) {
    -ms-grid-row: 1;
    grid-row-start: 1;
  }
  .pricing-table > div:nth-child(2) {
    -ms-grid-row: 2;
    grid-row-start: 2;
  }
  .pricing-table > div {
    -ms-flex-item-align: top;
    -ms-grid-row-align: top;
    align-self: top;
  }
}
html{
  max-width: 100vw;
  overflow-x: hidden;
}
.planheader{
  background-color: black;
  min-height: 400px;
  background-image: url("./components/image2.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  filter:brightness(50%)
}
.styled-select {
  appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  width: 100%;
  max-width: 200px; /* Adjust the width as needed */
  cursor: pointer;
}

.styled-select:focus {
  outline: none;
  border-color: #00ff0d; /* Highlight when focused */
}
.responsive-two-columns-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 80%; /* Adjust the maximum width as needed */
  border-radius: 10px;
  background-color: black;
  padding: 3%;
}

.responsive-column {
  flex-basis: calc(50% - 10px); /* Adjust the column width and spacing as needed */
  margin-bottom: 20px; /* Adjust the spacing between columns */
  padding: 20px;
 
  color: white;
 
  
  text-align: left;
}

/* Add media queries for responsiveness */
@media (max-width: 768px) {
  .responsive-column {
    flex-basis: 100%;
  }
  .header{
    font-size: 2rem !important;
  }
}
.cont3{
  background-image: url("https://i.postimg.cc/44rYBMQH/pexels-mart-production-7709296.jpg");
}
/* ActivePlans.css */

.active-plans-container {
  background-color: white; /* Paper-white background color */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: 0 auto;
  width :100%;
  margin: 20px;
}


.activecontainer{
  display: flex;
  
  justify-content: flex-start;
  gap: 20px;
  
}
.activebox li{
  list-style: none;
  text-decoration: none;
  text-align: left;
  font-weight: bold;
  
color: #ccc;
}
.activebox li h1{
font-size: medium;
}

.activebox{
  padding:20px;
  background: rgb(44, 44, 44);
  margin: 2px;
  border-radius: 10px;
}
/* Add media queries for mobile responsiveness */
@media (max-width: 768px) {
  .active-plans-container {
    font-size: 16px; /* Adjust font size for smaller screens */
    padding: 10px;
  }
  .activecontainer{
    flex-wrap: wrap;
  }
}
.pin-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.pin-modal {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 400px; /* You can adjust the max width */
  padding: 20px;
  text-align: center; padding: 10px;
}

.modal-content {
  margin: 0 auto;
}

.logo {
  width: 100px; /* Adjust the logo size as needed */
  margin: auto;
  margin-bottom: 20px;
}

.error {
  color: red;
  margin-top: 10px;
}
.batin{
  background-color: teal;
  color: white;
  border: 0.5px solid white;
  border-radius: 5px;
  width:120px;
  margin: auto;
  margin-top: 10px;
}
.inpat{
  padding: 10px;
  margin: auto;
  width: 150px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Create a container for the two columns */
.container {
  display: flex;
   /* Allow columns to wrap on smaller screens */
}

/* Define styles for the first column */
.column1 {
  flex: 1; /* Take up 50% of the container width on larger screens */
  background-image: url('https://i.postimg.cc/1tJj15Cg/image.png');
  background-size: cover;
  background-position: center;
  padding: 20px;
  color: white; /* Text color on top of the background image */
}

/* Define styles for the second column */
.column2 {
  flex: 1; /* Take up 50% of the container width on larger screens */
  text-align: center; /* Center-align the content */
  padding: 20px;
}

/* Style the responsive image */
.responsive-image {
  max-width: 100%;
  height: auto;
}

/* Make the columns stack on top of each other on smaller screens */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .column1,
  .column2 {
    flex: 100%; /* Take up 100% of the container width on smaller screens */
  }
}
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Center vertically in the viewport */
  margin-bottom:20px ;
}
.flx{
  display: flex;
  flex-wrap: wrap;
}
/* Style the image wrapper to add spacing and make it responsive */
.image-wrapper {
  margin: 10px; /* Add spacing between images */
}

/* Style the responsive image */
/* Styling for the modal overlay */
.notice-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's above other content */
}

/* Styling for the modal itself */
.notice-modal {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  text-align: center;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Styling for the close button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.cost-comparison-container {
  display: flex;
  justify-content: space-between;
}

/* Styling for each column */
.columnn {
  flex: 1;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

/* Styling for the lists */
ul {
  list-style: none;
  padding: 0;
}
.columnn ul li{
  display: flex;
  justify-content: space-between;
  padding: 2%;
  text-align: left;
}
/* Styling for list items with the green tick */
.green-tick {
  color: green;
  margin-left: 5px;
}

/* Styling for list items with the red cross */
.red-cross {
  color: red;
  margin-left: 5px;
}
.arrange{
  margin-top: 0% !important;


}
/* Make the container collapse into a column on mobile devices */
@media (max-width: 768px) {
  .cost-comparison-container {
    flex-direction: column;
  }

  .columnn {
    margin-bottom: 20px;
  }
}
/* Styling for the service grid container */
.service-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 80%;
  margin: auto;
}

/* Styling for each service card */
.service-card {
  flex: 0 0 calc(33.33% - 20px); /* Each card takes up 33.33% of the container width with spacing */
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  text-align: center;
  background-color: #ffffff;
}

/* Styling for the Font Awesome icons */
.icon {
  color: #01B580;
  font-size: 48px;
}

/* Styling for the service headers */
.sheader {
  font-size: 20px;
  margin: 10px 0;
  color: #000;
}

/* Styling for the service descriptions */
.description {
  font-size: 16px;
}
.responsive-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

/* Style the image container */
.image-container {
  flex: 1;
  text-align: center;
}

/* Style the responsive image */
.responsive-image {
  max-width: 100%;
  height: auto;
}

/* Style the text container */
.text-container {
  flex: 1;
  text-align: right;
  padding: 20px;
}

/* Style the text content */
.text-content {
  max-width: 400px; /* Adjust the maximum width as needed */
  margin: 0 auto;
}

/* Style the header */
.text-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

/* Style the paragraph */
.text-content p {
  font-size: 16px;
  margin-bottom: 20px;
}

/* Style the action button */
.action-button {
  background-color: #01B580;;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid snow;
  cursor: pointer;
}


.feature-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 85%;
  margin: auto;
  padding: 3%;
}

/* Styling for each feature card */
.feature-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.feature-card2{
  background-color: white;
  
  border-radius: 8px;
  padding: 10px;
  display: flex;
 gap: 10px;
}

/* Styling for the icon container */
.icon-container {
  background-color: rgba(0, 128, 128, 0.2); /* Teal background with transparency */
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  max-height: 50px;
}

/* Styling for the Font Awesome icons */
.icon2 {
  color: teal;
  font-size: 36px;
}

/* Styling for the title */
.title {
  font-size: 25px;
  margin-bottom: 10px;
  
}

/* Styling for the description */
.description2 {
  font-size: 16px;
}
.text-content2{
  text-align: left;
}
.responsive-section{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 90%;
  margin: 5%;
  background-color: #f6ecec73;
  border-radius: 10px;
  justify-content: center;
}
/* Make it responsive on smaller devices */
@media (max-width: 768px) {
  .responsive-section {
      flex-direction: column;
      text-align: center;
  }
  .image-container {
      order: 2; /* Move image below text on smaller screens */
  }
  .text-container {
      order: 1; /* Move text above image on smaller screens */
      text-align: center;
  }
  .text-content {
      max-width: none; /* Remove max width on smaller screens */
  }
  .feature-grid{
    grid-template-columns: repeat(1, 1fr);
  }
}
input{
  font-family: 'Courier New', Courier, monospace !important;
}